import * as React from 'react';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from 'lib/utils';

const textareaVariants = cva(
  'min-h-[80px]',
  {
    variants: {
      variant: {
        default: 'border border-gray-200 rounded-radius-xl p-[9px_14px] text-medium focus:outline-none disabled:bg-gray-50 disabled:text-gray-200 disabled:border-gray-100 disabled:cursor-not-allowed',
        old: 'flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>, VariantProps<typeof textareaVariants> {
  label?: string;
  labelClassName?: string;
  hintText?: string;
  errorText?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
    const {
      hintText,
      errorText,
      label,
      variant,
      className,
      labelClassName = '',
      ...rest
    } = props;

    return (
      <div className="flex flex-col gap-sm">
        {!!label && (
          <label
            className={`font-medium text-small max-w-max flex text-gray-900 relative ${rest.required ? `after:content-['*'] after:absolute after:left-[calc(100%+5px)] after:text-brand-600` : ''} ${labelClassName}`}
            htmlFor={rest.name}
          >
            {label}
          </label>
        )}
        <textarea
          className={cn(textareaVariants({ variant, className }))}
          ref={ref}
          {...rest}
        />
        {!!(errorText || hintText) && (
          <p
            className={`text-small ${!!errorText ? 'text-error-600' : rest.disabled ? 'text-gray-300' : 'text-gray-600'}`}>{errorText ? errorText : hintText}</p>
        )}
      </div>
    );
  }
);
Textarea.displayName = 'Textarea';

export { Textarea };
