import { createContext, ReactNode, useContext, useMemo } from 'react';
import { CurrentCommunityType } from 'types';
import { TELEGRAM_SUBDOMAIN_SUFFIX } from 'components/telegram';
import { useSearchParams } from 'next/navigation';


type CommunityContextType = {
  community: CurrentCommunityType | null;
};

export const CommunityContext = createContext({
  community: null
} as CommunityContextType);


export const GENERAL_SUBDOMAIN = 'app';

const STOP_SUBDOMAINS = ['www'];

export const CommunityContextProvider = ({ children }: { children: ReactNode }) => {
  const params = useSearchParams();

  const community = useMemo(() => {
    if (typeof window !== 'undefined') {
      let subdomain = window.location.hostname.split('.')[0];
      if (STOP_SUBDOMAINS.includes(subdomain)) return null;

      if (subdomain && subdomain.endsWith(TELEGRAM_SUBDOMAIN_SUFFIX)) {
        subdomain = subdomain.replace(TELEGRAM_SUBDOMAIN_SUFFIX, '');
      }

      if (subdomain === GENERAL_SUBDOMAIN) {
        subdomain = params?.get('community') || '';
      }
      return {
        name: subdomain,
        subdomain: subdomain
      } as CurrentCommunityType;
    }
    return null;
  }, [params]);

  return (
    <CommunityContext.Provider value={{ community }}>
      {children}
    </CommunityContext.Provider>
  );
};

export function useCommunity() {
  return useContext(CommunityContext);
}
