import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useCurrentUserQuery } from 'generated/hooks';
import { CurrentUserType } from 'types';
import { identifyUser } from 'app/_tracking';
import { UserNode } from '../generated/types';

type AuthContextType = {
  currentUser: UserNode | CurrentUserType | null;
  checkIsCurrentUser: (userId: string) => boolean;
  setCurrentUser: (user: CurrentUserType) => void;
};

export const AuthContext = createContext({
  currentUser: null,
  checkIsCurrentUser: () => false,
  setCurrentUser: () => {}
} as AuthContextType);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [res] = useCurrentUserQuery();

  const [currentUser, setCurrentUserRaw] = useState(() => {
    return res?.data?.currentUser as CurrentUserType;
  });

  const setCurrentUser = (user: CurrentUserType) => {
    setCurrentUserRaw(user);
    identifyUser(user);
  };

  useMemo(() => {
    if (!res.data?.currentUser) return null;
    setCurrentUser(res.data.currentUser);
    return res.data;
  }, [res]);

  const checkIsCurrentUser = (userId: string) => {
    return currentUser?.id === userId;
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        checkIsCurrentUser,
        setCurrentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
